exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-advanced-threats-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/advanced-threats/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-advanced-threats-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-bortel-shop-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/bortel-shop/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-bortel-shop-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-carbon-direct-solutions-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/carbon-direct-solutions/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-carbon-direct-solutions-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-ids-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/ids/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-ids-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-incident-response-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/incident-response/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-incident-response-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-public-sector-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/public-sector/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-public-sector-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-ransomware-mitigation-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/ransomware-mitigation/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-ransomware-mitigation-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-xdr-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/xdr/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-xdr-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

